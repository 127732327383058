<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.order_no"
        placeholder="订单号"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.buyer_id"
        placeholder="买家ID"
        style="width: 100px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.seller_id"
        placeholder="卖家ID"
        style="width: 100px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-select
        v-model="listQuery.type"
        placeholder="类型"
        style="width: 90px"
        class="filter-item"
        size="small"
        clearable
      >
        <el-option label="买单" :value="1" />
        <el-option label="卖单" :value="2" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="filter-container">
      <el-radio-group v-model="listQuery.status" size="small" @change="handleFilter">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button label="1">未匹配</el-radio-button>
        <el-radio-button label="2">待买家打款</el-radio-button>
        <el-radio-button label="3">已完成</el-radio-button>
        <el-radio-button label="4">已取消</el-radio-button>
      </el-radio-group>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="订单号" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column class-name="status-col" label="状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="primary">未匹配</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="success">待买家打款</el-tag>
          <el-tag v-else-if="scope.row.status === 3" type="success">已完成</el-tag>
          <el-tag v-else-if="scope.row.status === 4" type="success">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="买家" min-width="80" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.buyer_id }}</div>
          <div>{{ scope.row.buyer_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="卖家" min-width="80" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.seller_id }}</div>
          <div>{{ scope.row.seller_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单价" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.unit_price }}
        </template>
      </el-table-column>
      <el-table-column label="数量" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.amount }}
        </template>
      </el-table-column>
      <el-table-column label="总价" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_price }}
        </template>
      </el-table-column>
      <el-table-column label="买家手续费" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.buyer_fee}}
        </template>
      </el-table-column>
      <el-table-column label="买家应付款" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.buyer_price }}
        </template>
      </el-table-column>
      <el-table-column label="卖家手续费" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.seller_fee}}
        </template>
      </el-table-column>
      <el-table-column label="卖家应收款" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.seller_price }}
        </template>
      </el-table-column>
      
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        type: '',
        status: '',
        order_no: '',
        buyer_id: '',
        seller_id: '',
      },
      dialogFormVisible: false,
      btnLoading: false,
      form: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/order/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(object) {
      this.form = JSON.parse(JSON.stringify(object))
      this.dialogFormVisible = true
    },
    saveData() {
      this.btnLoading = true
      request({
        url: '/api/backend/task/save',
        method: 'post',
        data: this.form
      }).then(response => {
        this.btnLoading = false
        this.dialogFormVisible = false
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 2000
        })
        this.getList();
      }).catch((err) => {
        this.btnLoading = false
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
